  
// import { Toast } from 'vant'; 
import {setStore, getStore} from '@/utils/localStorage.js' 
import router from '@/router'    
// import { isAuth } from '@/api/repairApi'
// import { changeEstate } from '@/api/houseApi.js'
import { getToken, getInfo } from '@/api/userApi' 
import {removeToken} from '@/utils/token.js'
import { getUserInfo, getWxConfigs } from '@/api/wxApi'
const user = {
  state: { 
    openId: getStore({name: 'openId'}) || '', 
    token: getStore({name: 'Token'}) || '', 
    userInfo: getStore({name: 'userInfo'}) || '', 
    wxInfo: getStore({name: 'wxInfo'}) || '', 
    tenantId: getStore({name: 'tenantId'}) || '', 
    currentEstate: '', 
    isLoading: false, 
    // isLoading: true, 
  },
  actions: {
    GetAppid() { 
      return new Promise((resolve, reject) => { 
        let url = window.location.href.split('#')[0]
        getWxConfigs({ url: url }).then(res => {
          if (res.code === 200) {
            resolve(res.data)
          } else {
            reject()
          }
        })
      })
    },
    // 获取微信头像昵称和openid
    GetWxInfo({commit}, code) { 
      return new Promise((resolve, reject) => { 
        getUserInfo({code}).then(res => { 
          if (res.code === 200) {
            commit('SET_WX_INFO', res.data) 
            commit('SET_OPEN_ID', res.data.openid) 

            getToken({openid: res.data.openid, tenantId: user.state.tenantId}).then(res2 => {
              if (res2.code === 1001 || res2.code === '1001') {
                removeToken()
                // router.push('/register')
                router.replace('/register')
                reject(res2.data) 
              } else {
                // 
                commit('SET_TOKEN', res2.access_token)  
                commit('SET_TENANT_ID', res2.tenant_id)
                router.go(0)
                
                // getInfo().then(res2 => { 
                //   if (res2.code === 200) {
                //     commit('SET_USER_INFO', res2.data) 
                //     resolve(res2.data)
                //   } else {
                //     reject()
                //   }
                // }) 
              }
            })

            resolve(res.data)

          } else {
            reject()
          }
        }) 
      })
    },
    GetInfo({commit}) { 
      return new Promise((resolve, reject) => { 
        getInfo().then(res => { 
          if (res.code === 200) {
            commit('SET_USER_INFO', res.data) 
            resolve(res.data)
          } else {
            reject()
          }
        }) 
      })
    },
    GetToken({commit}, isHome) {
      return new Promise((resolve, reject) => {
        getToken({openid: user.state.openId, tenantId: user.state.tenantId}).then(res => {
          if (res.code === 1001 || res.code === '1001') {
            removeToken()
            // router.push('/register')
            router.replace('/register')
            reject(res.data) 
          } else {
            // 
            commit('SET_TOKEN', res.access_token)  
            commit('SET_TENANT_ID', res.tenant_id)
            // 如果是跳首页没token
            if(isHome) {
              router.go(0)
            }
            getInfo().then(res2 => { 
              if (res2.code === 200) {
                commit('SET_USER_INFO', res2.data) 
                resolve(res2.data)
              } else {
                reject()
              }
            }) 
          }
        })  
      })
    },
    // GetToken({commit}) {
    //   return new Promise((resolve, reject) => {
    //     getToken({openid: user.state.openId}).then(res => {
    //       if (res.code === 1001 || res.code === '1001') { 
    //         reject(res.data) 
    //       } else {
    //         commit('SET_TOKEN', res.access_token) 
    //         resolve(res.data)  
    //       }
    //     })  
    //   })
    // }
  },
  mutations: {
    SET_TENANT_ID: (state, tenantId) => { 
      state.tenantId = tenantId
      setStore({name: 'tenantId', content: tenantId});
    }, 
    SET_OPEN_ID: (state, openId) => { 
      state.openId = openId
      setStore({name: 'openId', content: openId});
    }, 
    SET_TOKEN: (state, token) => { 
      state.token = token
      setStore({name: 'Token', content: token});
    }, 
    SET_IS_LOADING: (state, isLoading) => { 
      state.isLoading = isLoading
      // setStore({name: 'isLoading', content: isLoading});
    }, 
    SET_USER_INFO: (state, userInfo) => { 
      state.userInfo = userInfo
      setStore({name: 'userInfo', content: userInfo});
    }, 
    SET_WX_INFO: (state, wxInfo) => { 
      state.wxInfo = wxInfo
      setStore({name: 'wxInfo', content: wxInfo});
    },
    SET_CURRENT_ESTATE: (state, currentEstate) => { 
      state.currentEstate = currentEstate
      setStore({name: 'currentEstate', content: currentEstate});
    }, 
  }

};
export default user;
