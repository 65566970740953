import 'amfe-flexible' 
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store' 

import Vant from 'vant';
import 'vant/lib/index.css';
import publicMethod from '@/utils/publicMethod'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import './style/index.scss'

Vue.config.ignoredElements = ['wx-open-launch-weapp'];
Vue.use(VideoPlayer)

Vue.use(publicMethod);
Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
