
import axios from '@/utils/axios'
const baseURL = '/api'

// //  
// export const getWxConfigs = (data) => {
//   return axios({
//     baseURL,
//     url: '/wxsys/getWxConfigs',
//     method: 'post',
//     data
//   });
// };

export const getWxConfigs = (params) => {
  return axios({
    baseURL,
    url: '/app/open/weixin/getConfig',
    method: 'get',
    params
  });
};

export const getOpenid = (params) => {
  return axios({
    baseURL,
    url: '/app/open/weixin/getOpenId',
    method: 'get',
    params
  });
};

export const getUserInfo = (params) => {
  return axios({
    baseURL,
    url: '/app/open/weixin/getUserInfo',
    method: 'get',
    params
  });
};