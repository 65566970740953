


import axios from '@/utils/axios';
const baseURL = '/api'

// 获取 token
export const getToken = (params) => {
  return axios({
    baseURL,
    url: '/blade-auth/oauth/token/wx',
    method: 'post',
    params
  });
};
// 获取 getInfo
export const getInfo = (params) => {
  return axios({
    baseURL,
    url: '/app/user/info', 
    method: 'get',
    params
  });
};
// 完善个人信息 submitInfo
export const submitInfo = (data) => {
  return axios({
    baseURL,
    url: '/app/open/user/submitInfo', 
    method: 'post',
    data
  });
};
// 公众号注册
export const bindUser = (data) => {
  return axios({
    baseURL,
    url: '/app/open/user/apply', 
    method: 'post',
    data
  });
};
// 根据角色获取人员列表
export const getList = (params) => {
  return axios({
    baseURL,
    url: '/blade-user/wx/admin/list', 
    method: 'get',
    params
  });
};
// 获取角色列表
export const getRoleList = (params) => {
  return axios({
    baseURL,
    url: '/app/open/dict/list?code=role_list', 
    method: 'get',
    params
  });
};