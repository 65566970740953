<template>
  <div id="app"> 
    <router-view/>
    <div v-if="isLoading" class="loading">
        <van-loading  size="24px" vertical>加载中...</van-loading>
    </div>
  </div>
</template>
<script> 
// import {removeStore} from '@/utils/localStorage'
import {removeToken} from '@/utils/token'
// import {mapState} from 'vuex'
export default {
  data(){
    return {
      // isLoading: true,
      code: '',
    }
  },
  computed: { 
    //   统一添加页面 loading
    isLoading() {
      return this.$store.getters.isLoading
    }
  },
  async created() {},
  mounted() {
    // window.addEventListener('beforeunload',()=>{
    //   removeStore({name: 'token'})
    // });
  },  
  methods: {
    
  },
  beforeDestroy() {
    removeToken()
    this.$store.commit('SET_IS_LOADING', false)
  }
}
</script>
<style lang="scss">
// @import url("./style/index.scss");
</style>
<style lang="scss" scoped>
  .loading {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    padding-top: 5.333333rem;
    z-index: 9;
    background-color: rgba(255,255,255, 0.5);
  }
</style>
