
import {getStore, removeStore} from './localStorage';
const TokenKey = 'Token';

export function getToken() {
  return getStore({name: TokenKey}) || '';
}

export function removeToken() { 
  return removeStore({name: TokenKey}) || '';
}

export function removeUsernfo() { 
  removeStore({name: 'currentEstate'}) || '';
  return removeStore({name: 'userInfo'}) || '';
}