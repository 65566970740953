import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user.js'
import getters from './getters.js'

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  state: {
    actEstate: ''  // 添加房产选择小区
  },
  mutations: {
    SET_ACT_ESTATE: (state, data) => {
      state.actEstate = data
      // localStorage.setItem('actEstate', data);
    },
  },
  actions: {},
  modules: {
    user: user
  },
})