<template>
    <div style="height: 100%"> 
        <van-nav-bar class="my_nav" :title="title" left-arrow fixed @click-left="$back" :right-text="rightTopBtnName" @click-right="onClickRight(rightTopBtnPath)"/>
        <router-view style="padding-top: 1.22667rem; height: 100%; overflow-y: auto;"/> 
    </div> 
</template>

<script>
export default { 
    data(){ 
        return{
            title: '',
            rightTopBtnPath:'',
            rightTopBtnName:'',
        }
    }, 
    watch: {
        '$route': {
            handler: function() { 
                this.title = this.$route.meta.title
            },
            deep: true
        } 
    },
    created() { 
        this.title = this.$route.meta.title
        this.rightTopBtnName = this.$route.meta.rightTopBtn ? this.$route.meta.rightTopBtn.rightTopBtnName : ''
        this.rightTopBtnPath = this.$route.meta.rightTopBtn ? this.$route.meta.rightTopBtn.rightTopBtnPath : ''
        console.log(this.$route)
        let arr = [
                '/alertIssued',
                '/socialSharing', // 社情反映
                '/socialSharingList', // 社情共享
                '/socialSharingListDetail', // 社情共享详情
                '/veterans',
                '/veteransInforEntry',
                '/veteransActivityList',
                '/veteransActivityDetail',
                '/message',
                '/messageDetail',
                '/voting',
                '/votingDetail',  
                '/elderly',
                '/binding',
                '/register',
                '/ask',
                '/appraise',
                '/answer',
                '/opinion',
                '/myanswerList',
                '/myanswerDetail',
                '/bind',
                '/warning',
                '/serviceApplication',
                '/test',
                '/propertyRepair',
                '/repairRecords',
                '/recordsDetails',

                '/myWarningList',
                '/myWarningDetail',
                '/warningInfo',
                '/warningInfoDetail',
                '/threeAffairs',
                '/threeAffairsDetail',
                '/preventionList',
                '/preventionDetail',
                '/residentsAppeal',
                '/employmentInformation',
                '/employmentInformationDetail',
                '/emergencyNotification',
                '/applicationList',
                '/applicationDetail',
                '/visitorApplication',
                '/myRepairList',
                '/myWorkList',
                '/myWorkListDetail',
                '/myRepairDetail',
                '/myAppealList',
                '/myAppealDetail',
                '/policyList',
                '/policyDetail',
                '/houseRegister',
                '/memberRegister',
                '/memberVerifyList',
                '/memberVerifyDetail',
                '/faceUP',
                '/myProperty',
                '/myPropertyDetail',
                '/estateList',
                '/registrationVoters',
                '/registrationList',
                '/registrationDetail',

                '/paySuccess',
                '/propertyFee',
                '/propertyFeeDetail',
                '/paymentRecords',
                '/paymentRecordsDetail',
                '/my',
                '/community',
                '/messageList',
                '/myCar',
                '/addCar',
                '/discussion',
                '/discussionDetail',
                '/myOpinionList',
                '/myOpinionDetail',

            ]
        if (arr.indexOf(this.$route.path) === -1) { 
            window.location.href = '/home'
        }
    },
    methods:{
        onClickRight(val){
            window.location.href = val
        }
    }
}
</script>

<style lang="scss" scoped> 
.pp {
    overflow-y: auto;
}
.van-nav-bar {
  z-index: 9;
}
</style>
