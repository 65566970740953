/* eslint-disable */
import axios from 'axios'
import {Toast} from 'vant'
import { Dialog } from 'vant';
import wx from 'weixin-jsapi'
 
import router from '@/router'
import store from '@/store' 

// import {getRequestParams, isEmpty, randomLenStr, serialize} from '@/utils/encryptDecrypt/util';
import { getRequestParams, isEmpty, randomLenStr, containUrl } from '@/utils/encryptDecrypt/util.js'
import crypto from '@/utils/encryptDecrypt/crypto';
import website from '@/config/index'  
import {getToken} from '@/utils/token.js'
///////////////////////////////////
// 系统全局请求对象
const service = axios.create({
  baseURL: process.env.BASE_API,
  timeout: 40000,
  responseType: 'json',
  headers: {'content-type': 'application/json', 'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'}
})

// 不加 token 的接口列表
const excludeList = [];
// 对body不加密列表，上传文件类接口
const excludeBodyList = [];
  // 对params不加密 接口列表
// const excludeParamsList = [];

service.interceptors.request.use(config => {
  
  var {hasAuthorization = true, aloneCryptoMode = true} = {...config.headers, ...config.data, ...config.params};

    config.headers['Authorization'] = `Basic c2FiZXI6c2FiZXJfc2VjcmV0` 
  //让每个请求携带token 
  if (getToken()) {
    config.headers['Blade-Auth'] = 'bearer ' + getToken()
  } else {
    
    // config.headers['Blade-Auth'] = 'bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiIwMDAwMDAiLCJyb2xlX25hbWUiOiJyZXNpZGVudCIsInVzZXJfaWQiOiIxNTI4NjQxNzExNTk2ODc1Nzc4Iiwicm9sZV9pZCI6IjMiLCJ1c2VyX25hbWUiOiIzNzEzMTExOTk3MDYwOTMzMzMiLCJuaWNrX25hbWUiOiLnrYnmiJHkuIDkuIsiLCJkZXRhaWwiOnsidHlwZSI6ImFwcCJ9LCJ0b2tlbl90eXBlIjoiYWNjZXNzX3Rva2VuIiwiZGVwdF9pZCI6IjE0NDE5NTIzNDcyNDkxNDc5MDYiLCJhY2NvdW50IjoiMzcxMzExMTk5NzA2MDkzMzMzIiwiY2xpZW50X2lkIjoic2FiZXIiLCJleHAiOjE2NTM4OTYzNzUsIm5iZiI6MTY1MzI5MTU3NX0.QM-EgHir5SyBGY0KuRF-LURJUpQWENaVUQ32jFgq2TvrIG0rjWv0BmvtvVByyw3SuiGtPsSlMPotbGcl2Rw-WA'
  }
  // let openid = getStore({name: 'openId'}) && store.getters.openId
  // if (!openid) { 
  //   console.log('没获取到本地存的openid， 请求封装中') 
  // }
  // config.headers['token'] = openid
  //////////////////////////////

  //headers中配置serialize为true开启序列化
  // if (config.method === 'post') {
  //   config.data = serialize(config.data);
  // }
  // 数据加密
  if (website.cryptoMode && aloneCryptoMode && !containUrl(excludeList, config.url)) {
    const aesKey = randomLenStr(16);
    const key = crypto.encryptRSA(aesKey);
    let timestamp = new Date().getTime();
    let nonce = crypto.initNonce(timestamp);
    let hasBody = false;

    if (!isEmpty(config.data) && !containUrl(excludeBodyList, config.url)) {
      hasBody = true;
      let body = {...config.data};
      body.nonce = nonce;
      body.timestamp = timestamp;
      let stringA = crypto.initSort(body);
      body.sign = crypto.initSign(stringA, aesKey);
      config.data = {
        data: crypto.encryptAES(JSON.stringify(body), aesKey),
        key
      };
    }

    let paramsJson = getRequestParams(config.url);
    if (paramsJson) {
      config.params = Object.assign({}, config.params, paramsJson);
      config.url = config.url.substring(0, config.url.indexOf('?'));
    }
    if (!isEmpty(config.params) || !hasBody) {
      let params = Object.assign({}, config.params);
      params.nonce = crypto.initNonce(timestamp);
      params.timestamp = timestamp;
      let stringA = crypto.initSort(params);
      params.sign = crypto.initSign(stringA, aesKey);
      config.params = {
        data: crypto.encryptAES(JSON.stringify(params), aesKey),
        key
      };
    }
  }
  //////////////////////////////
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use((response) => {
  ///////////////////////////
  let data = response.data 
  if (website.cryptoMode && data) {
    try {
      let result = data.result;
      let rsaKey = data.key;
      if (result && rsaKey) {
        let key = crypto.decryptRSA(rsaKey);
        let decryptData = crypto.decryptAES(result, key);
        data = JSON.parse(decryptData);
      }
    } catch (e) {
      // console.log(e.message);
    }
  }
  
  if (data) { 
    if (data.code === 200 || data.code === '200') {
      return Promise.resolve(data)
    } else if (data.code === 1001) {
      router.push('/register')
      return false
    } else if (data.code === 1002 || data.code === '1002') {
      // router.push('/register')
      Dialog.alert({
        message: data.msg,
      }).then(() => {
        // on close
        wx.closeWindow()
      });
      return false
    } else if (data.code === 401) {
      store.dispatch('GetToken').then(() => {
        // router.push('/home')
        Toast({
          message: '认证已失效，正在登录!', 
          duration: 500,
          onClose: function() {
            // router.push('/home')
          }
        })
      }) 
      
      return Promise.reject(new Error('token expired'));
    } else {
      // Toast.fail(data.message)
      return Promise.resolve(data)
    }
  } else {
    Toast.fail('网络出现问题!') 
    return Promise.reject(new Error('data is null'))
  }
}, (error) => {
  if (error.response) {
    let resp = error.response;

    if (website.cryptoMode && resp.data) {
      try {
        let result = resp.data.result;
        let rsaKey = resp.data.key;
        if (result && rsaKey) {
          let key = crypto.decryptRSA(rsaKey);
          let decryptData = crypto.decryptAES(result, key);
          resp.data = JSON.parse(decryptData);
        }
      } catch (e) {
        // console.log(e.message);
      }
    }
    store.commit('SET_IS_LOADING', false)
    const errorMessage = resp.data === null ? '系统内部异常，请联系网站管理员' : resp.data.message
    switch (resp.status) {
      case 404:
        Toast('很抱歉，资源未找到')
        break;
      case 403:
        Toast('很抱歉，您暂无该操作权限')
        break
      case 401: 
        store.dispatch('GetToken').then(() => {
          // router.push('/home')
          Toast({
            message: '认证已失效，正在登录!', 
            duration: 500,
            onClose: function() {
              // router.push('/home')
            }
          })
        })
        
        break
      case 400:
        Toast(resp.data.msg)
        break
      case 413:
        Toast(resp.data.msg ?  resp.data.msg : '上传文件过大')
        break
      default:
        Toast(resp.data.msg ?  resp.data.msg : '网络出现问题，请稍后再试') 
        break
    }
  } else {
    Toast.fail('网络出现问题')
    // router.push('/404');
  }
  return Promise.reject(error)
})
export default service
