import CryptoJS from 'crypto-js';
import NodeRSA from 'node-rsa';
import md5 from 'js-md5';
import {isEmpty, randomLenNum, randomLenStr, getRequestParams} from './util'; 
import config from '@/config/index' 
console.log(config.cryptoMode)
export default class crypto {

  static PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1k/X2SxMVImZgkqKAGKEPvr+WxvXBXmGI4frdGgOxwR56PGRTpsTju/OzuIDznyt14EeHczm3v42r+zjtwUyTUZhDAeWtFPs8Sz6TpNcEv34dH94G4oaY0D0jjrdX3P+z44ETCxbpWWYbdtQDw+7jANOVd0ICsnLN3mEG1DEu9wIDAQAB-----END PUBLIC KEY-----';

  /**
   * aes 加密方法，同java：AesUtil.encryptToBase64(text, aesKey);
   */
  static encryptAES(data, key) {
    const dataBytes = CryptoJS.enc.Utf8.parse(data);
    const keyBytes = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.AES.encrypt(dataBytes, keyBytes, {
      iv: keyBytes,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  }

  /**
   * aes 解密方法，同java：AesUtil.decryptFormBase64ToString(encrypt, aesKey);
   */
  static decryptAES(data, key) {
    const keyBytes = CryptoJS.enc.Utf8.parse(key);
    const decrypted = CryptoJS.AES.decrypt(data, keyBytes, {
      iv: keyBytes,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decrypted);
  }

  /**
   * RSA公钥加密方法
   */
  static encryptRSA(data) {
    let key = new NodeRSA(this.PUBLIC_KEY);
    key.setOptions({encryptionScheme: 'pkcs1'});
    return key.encrypt(data, 'base64');
  }

  /**
   * RSA公钥解密方法
   */
  static decryptRSA(data) {
    let key = new NodeRSA(this.PUBLIC_KEY);
    key.setOptions({encryptionScheme: 'pkcs1'});
    return key.decryptPublic(data, 'utf-8');
  }

  /**
   * 生成nonce
   * @param timestamp
   * @returns {*}
   */
  static initNonce(timestamp) {
    let uuid = randomLenStr();
    let random = randomLenNum(10);
    return md5(timestamp + uuid + random);
  }

  /**
   * 对参数排序后链接成字符串A
   */
  static initSort(params) {
    let arr = [];
    let keys = Object.keys(params);
    let sortKeys = keys.sort();
    for (let key of sortKeys) {
      if (key && !isEmpty(params[key])) {
        let val = params[key];
        if (typeof val === 'object') {
          val = JSON.stringify(params[key]);
        }
        arr.push(key + '=' + val);
      }
    }
    return arr.join('&');
  }

  /**
   * 生成sign签名
   */
  static initSign(stringA, secret) {
    let stringB = stringA + '&secret=' + secret;
    return md5(stringB).toUpperCase();
  }

  static cryptoParams = (url) => {
    if (config.cryptoMode) {
      const aesKey = randomLenStr(16);
      const key = crypto.encryptRSA(aesKey);
      let timestamp = new Date().getTime();
      let params = getRequestParams(url);
      if (!params) {
        params = {};
      }
      params.nonce = crypto.initNonce(timestamp);
      params.timestamp = timestamp;
      let stringA = crypto.initSort(params);
      params.sign = crypto.initSign(stringA, aesKey);
      let data = crypto.encryptAES(JSON.stringify(params), aesKey);
      url = url.substring(0, url.indexOf('?'));
      url += `?data=${encodeURIComponent(data)}&key=${encodeURIComponent(key)}`;
    }
    return url;
  };
}
