const getters = {
    openId: state => state.user.openId,
    token: state => state.user.token,
    userInfo: state => state.user.userInfo,
    wxInfo: state => state.user.wxInfo,
    isLoading: state => state.user.isLoading,
    tenantId: state => state.user.tenantId,
    currentEstate: state => state.user.currentEstate,
  }
  export default getters
  