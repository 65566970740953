import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout'

Vue.use(VueRouter)

//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  // 首页
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home')
  },
  // 社区
  {
    path: '/community',
    name: 'Community',
    component: () => import('@/views/tabber/community/community')
  },
  // 我的
  {
    path: '/my',
    name: 'My',
    component: () => import('@/views/tabber/my/my')
  },
  {
    path: '/',
    component: Layout,
    // redirect: '/home',
    children: [
      // 议事协商
      {
        path: '/discussion',
        name: 'Discussion',
        component: () => import('@/views/discussion/discussion.vue'),
        meta: {
          title: '议事协商'
        },
      },
      // 议事协商详情
      {
        path: '/discussionDetail',
        name: 'DiscussionDetail',
        component: () => import('@/views/discussion/discussionDetail.vue'),
        meta: {
          title: '议事协商详情'
        },
      },
      // 社情反映
      {
        path: '/socialSharing',
        name: 'socialSharing',
        component: () => import('@/views/send/socialSharing/add.vue'),
        meta: {
          title: '社情反映',
          rightTopBtn:{
            rightTopBtnPath:'/socialSharingList',
            rightTopBtnName:'社情共享'
          }
        },
      },
      // 社情共享
      {
        path: '/socialSharingList',
        name: 'socialSharingList',
        component: () => import('@/views/send/socialSharing/list.vue'),
        meta: {
          title: '社情共享'
        },
      },
      // 社情共享详情
      {
        path: '/socialSharingListDetail',
        name: 'socialSharingListDetail',
        component: () => import('@/views/send/socialSharing/detail.vue'),
        meta: {
          title: '详情'
        },
      },
      // 警情发布
      {
        path: '/alertIssued',
        name: 'evealertIssuednt',
        component: () => import('@/views/send/alertIssued/index.vue'),
        meta: {
          title: '警情'
        },
      },
      // 在线指导 提问
      {
        path: '/ask',
        name: 'ask',
        component: () => import('@/views/ask/index.vue'),
        meta: {
          title: '在线指导'
        },
      },
      // 评价（事件、调解、在线指导）
      {
        path: '/appraise',
        name: 'appraise',
        component: () => import('@/views/appraise/index.vue'),
        meta: {
          title: '评价'
        },
      },
      // 专家回答问题
      {
        path: '/answer',
        name: 'answer',
        component: () => import('@/views/ask/answer.vue'),
        meta: {
          title: '问题解答'
        },
      },
      // 意见反馈
      {
        path: '/opinion',
        name: 'opinion',
        component: () => import('@/views/opinion/index.vue'),
        meta: {
          title: '意见反馈',
          rightTopBtn:{
            rightTopBtnPath:'/myOpinionList',
            rightTopBtnName:'反馈记录'
          }
        }
      },
      // 我的反馈列表
      {
        path: '/myOpinionList',
        name: 'myOpinionList',
        component: () => import('@/views/opinion/list.vue'),
        meta: {
          title: '我的反馈列表'
        },
      },
      // 我的反馈详情
      {
        path: '/myOpinionDetail',
        name: 'myOpinionDetail',
        component: () => import('@/views/opinion/detail.vue'),
        meta: {
          title: '列表'
        },
      },
      // 问题列表
      {
        path: '/myanswerList',
        name: 'myanswerList',
        component: () => import('@/views/ask/myanswer/list.vue'),
        meta: {
          title: '我的问题列表'
        },
      },
      // 问题详情
      {
        path: '/myanswerDetail',
        name: 'myanswerDetail',
        component: () => import('@/views/ask/myanswer/detail.vue'),
        meta: {
          title: '问题详情'
        },
      },
      
      // 绑定
      {
        path: '/bind',
        name: 'bind',
        component: () => import('@/views/bind.vue'),
        meta: {
          title: '绑定'
        },
      },

      // 退役军人 - 录入
      {
        path: '/veterans',
        name: 'Veterans',
        // component: () => import('@/views/Veterans'),
        component: () => import('@/views/Veterans/model/activityList'),
        meta: {
          title: '活动列表'
        },
      },
      // 退役军人 - 军人信息录入
      {
        path: '/veteransInforEntry',
        name: 'VeteransInforEntry',
        component: () => import('@/views/Veterans/model/inforEntry'),
        meta: {
          title: '军人信息录入'
        },
      },
      // 退役军人 - 活动列表
      {
        path: '/veteransActivityList',
        name: 'VeteransActivityList',
        component: () => import('@/views/Veterans/model/activityList'),
        meta: {
          title: '活动列表'
        },
      },
      // 退役军人 - 活动详情
      {
        path: '/veteransActivityDetail',
        name: 'VeteransActivityDetail',
        component: () => import('@/views/Veterans/model/activityDetail'),
        meta: {
          title: '活动详情'
        },
      },
      // 社区通知 - 列表
      {
        path: '/message',
        name: 'Message',
        component: () => import('@/views/Message'),
        meta: {
          title: '社区通知'
        },
      },
      // 社区通知 - 详情
      {
        path: '/messageDetail',
        name: 'MessageDetail',
        component: () => import('@/views/Message/model/detail'),
        meta: {
          title: '信息详情'
        },
      },
      // 居民电子投票 - 列表
      {
        path: '/voting',
        name: 'Voting',
        component: () => import('@/views/Voting'),
        meta: {
          title: '居民电子投票'
        }
      },
      // 居民电子投票 - 详情
      {
        path: '/votingDetail',
        name: 'VotingDetail',
        component: () => import('@/views/Voting/model/detail'),
        meta: {
          title: '居民电子投票详情'
        }
      },
      // 居家养老 
      {
        path: '/elderly',
        name: 'Elderly',
        component: () => import('@/views/Binding'),
        meta: {
          title: '居家养老'
        }
      },
      // 老人电子手环绑定 
      {
        path: '/binding',
        name: 'Binding',
        component: () => import('@/views/Binding/model/binding'),
        meta: {
          title: '老人电子手环绑定'
        }
      },
      // 注册 
      {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/register'),
        meta: {
          title: '完善个人信息'
        }
      },
      // 警情上报 
      {
        path: '/warning',
        name: 'warning',
        component: () => import('@/views/safe/warning'),
        meta: {
          title: '警情上报',
          rightTopBtn:{
            rightTopBtnPath:'/myWarningList',
            rightTopBtnName:'上报记录'
          }
        },
      },
      // 服务申请 
      // {
      //   path: '/serviceApplication',
      //   name: 'serviceApplication',
      //   component: () => import('@/views/safe/serviceApplication'),
      //   meta: {
      //     title: '服务申请',
      //     rightTopBtn:{
      //       rightTopBtnPath:'/applicationList',
      //       rightTopBtnName:'申请记录'
      //     }
      //   }
      // },
      
      // 物业报修 
      {
        path: '/propertyRepair',
        name: 'PropertyRepair',
        component: () => import('@/views/repair/index'),
        meta: {
          title: '物业报修',
          rightTopBtn:{
            rightTopBtnPath:'/repairRecords',
            rightTopBtnName:'报修记录'
          }
        }
      },
      // 物业报修记录
      {
        path: '/repairRecords',
        name: 'RepairRecords',
        component: () => import('@/views/repair/repairRecords'),
        meta: {
          title: '报修记录'
        }
      },
      // 报修详情
      {
        path: '/recordsDetails',
        name: 'recordsDetails',
        component: () => import('@/views/repair/recordsDetails'),
        meta: {
          title: '报修详情'
        }
      },
      // 居民诉求
      {
        path: '/residentsAppeal',
        name: 'ResidentsAppeal',
        component: () => import('@/views/ResidentsAppeal'),
        meta: {
          title: '居民诉求',
          rightTopBtn:{
            rightTopBtnPath:'/myAppealList',
            rightTopBtnName:'我的诉求'
          }
        }
      },
      // 居民诉求列表
      {
        path: '/myAppealList',
        name: 'myAppealList',
        component: () => import('@/views/ResidentsAppeal/list'),
        meta: {
          title: '我的诉求'
        }
      },
      // 居民诉求详情
      {
        path: '/myAppealDetail',
        name: 'myAppealDetail',
        component: () => import('@/views/ResidentsAppeal/detail'),
        meta: {
          title: '诉求详情'
        }
      },
      // 警情上报列表
      {
        path: '/myWarningList',
        name: 'myWarningList',
        component: () => import('@/views/safe/myWarningList'),
        meta: {
          title: '我的警情上报'
        }
      },
      // 警情上报详情
      {
        path: '/myWarningDetail',
        name: 'myWarningDetail',
        component: () => import('@/views/safe/myWarningDetail'),
        meta: {
          title: '警情上报详情'
        }
      },
      // 预警信息列表
      {
        path: '/warningInfo',
        name: 'warningInfo',
        component: () => import('@/views/safe/warningInfo'),
        meta: {
          title: '预警信息'
        }
      },
      // 预警信息详情
      {
        path: '/warningInfoDetail',
        name: 'warningInfoDetail',
        component: () => import('@/views/safe/warningInfoDetail'),
        meta: {
          title: '详情'
        }
      },
      // 三务公开列表
      {
        path: '/threeAffairs',
        name: 'threeAffairs',
        component: () => import('@/views/party/three-affairs'),
        meta: {
          title: '三务公开'
        }
      },
      // 三务公开详情
      {
        path: '/threeAffairsDetail',
        name: 'threeAffairsDetail',
        component: () => import('@/views/party/three-affairs-detail'),
        meta: {
          title: '详情'
        }
      },
      // 防控信息列表
      {
        path: '/preventionList',
        name: 'preventionList',
        component: () => import('@/views/virus/prevention-list'),
        meta: {
          title: '防控信息'
        }
      },
      // 防控信息详情
      {
        path: '/preventionDetail',
        name: 'preventionDetail',
        component: () => import('@/views/virus/prevention-detail'),
        meta: {
          title: '详情'
        }
      },
      // 就业信息
      {
        path: '/employmentInformation',
        name: 'EmploymentInformation',
        component: () => import('@/views/EmploymentInformation'),
        meta: {
          title: '就业信息'
        }
      },
      // 应急通知 - 详情
      {
        path: '/employmentInformationDetail',
        name: 'EmploymentInformationDetail',
        component: () => import('@/views/EmploymentInformation/model/detail'),
        meta: {
          title: '详情'
        }
      },
      // 应急通知
      {
        path: '/emergencyNotification',
        name: 'EmergencyNotification',
        component: () => import('@/views/EmergencyNotification'),
        meta: {
          title: '应急通知'
        }
      },
      // // 服务申请列表
      // {
      //   path: '/applicationList',
      //   name: 'applicationList',
      //   component: () => import('@/views/safe/applicationList'),
      //   meta: {
      //     title: '申请记录'
      //   }
      // },
      // // 服务申请详情
      // {
      //   path: '/applicationDetail',
      //   name: 'applicationDetail',
      //   component: () => import('@/views/safe/applicationDetail'),
      //   meta: {
      //     title: '服务申请详情'
      //   }
      // },
      // 访客申请
      {
        path: '/visitorApplication',
        name: 'VisitorApplication',
        component: () => import('@/views/VisitorApplication'),
        meta: {
          title: '访客申请'
        }
      },
      {
        path: '/myRepairList',
        name: 'myRepairList',
        component: () => import('@/views/repair/myRepairList'),
        meta: {
          title: '我的报修记录'
        }
      },
      {
        path: '/myWorkList',
        name: 'myWorkList',
        component: () => import('@/views/repair/myWorkList'),
        meta: {
          title: '我的工作记录'
        }
      },
      {
        path: '/myWorkListDetail',
        name: 'myWorkListDetail',
        component: () => import('@/views/repair/detail'),
        meta: {
          title: '我的工作记录'
        }
      },
      {
        path: '/myRepairDetail',
        name: 'myRepairDetail',
        component: () => import('@/views/repair/detail'),
        meta: {
          title: '我的报修记录'
        }
      },
      // 政策服务列表
      {
        path: '/policyList',
        name: 'policyList',
        component: () => import('@/views/policy/list'),
        meta: {
          title: '政策服务'
        }
      },
      // 政策服务详情
      {
        path: '/policyDetail',
        name: 'policyDetail',
        component: () => import('@/views/policy/detail'),
        meta: {
          title: '政策服务详情'
        }
      },
      // 房屋登记
      {
        path: '/houseRegister',
        name: 'houseRegister',
        component: () => import('@/views/House/register'),
        meta: {
          title: '房屋登记'
        }
      },
      // 人员登记
      {
        path: '/memberRegister',
        name: 'memberRegister',
        component: () => import('@/views/House/memberRegister'),
        meta: {
          title: '人员登记'
        }
      },
      // 住户审核
      {
        path: '/memberVerifyList',
        name: 'memberVerifyList',
        component: () => import('@/views/House/verifyList'),
        meta: {
          title: '住户审核'
        }
      },
      // 住户审核详情
      {
        path: '/memberVerifyDetail',
        name: 'memberVerifyDetail',
        component: () => import('@/views/House/verifyDetail'),
        meta: {
          title: '住户审核'
        }
      },
      // 人脸上传 列表
      {
        path: '/faceUP',
        name: 'faceUP',
        component: () => import('@/views/House/face-upload'),
        meta: {
          title: '人脸上传'
        }
      },
      // 我的房产 列表
      {
        path: '/myProperty',
        name: 'myProperty',
        component: () => import('@/views/House/myProperty'),
        meta: {
          title: '我的房产'
        }
      },
      // 我的房产 列表
      {
        path: '/myPropertyDetail',
        name: 'myPropertyDetail',
        component: () => import('@/views/House/myPropertyDetail'),
        meta: {
          title: '我的房产'
        }
      },
      // 所有小区 列表
      {
        path: '/estateList',
        name: 'estateList',
        component: () => import('@/views/House/estate-list'),
        meta: {
          title: '小区列表'
        }
      },
      // 选民登记
      {
        path: '/registrationVoters',
        name: 'RegistrationVoters',
        component: () => import('@/views/serve/registrationVoters'),
        meta: {
          title: '选民登记',
          rightTopBtn:{
            rightTopBtnPath:'/registrationList',
            rightTopBtnName:'我的登记'
          }
        }
      },
      // 选民登记 列表
      {
        path: '/registrationList',
        name: 'RegistrationList',
        component: () => import('@/views/serve/registrationList'),
        meta: {
          title: '选民登记记录'
        }
      },
      // 选民登记 详情
      {
        path: '/registrationDetail',
        name: 'RegistrationDetail',
        component: () => import('@/views/serve/registrationDetail'),
        meta: {
          title: '选民登记详情'
        }
      },
      // 缴费成功 
      {
        path: '/paySuccess',
        name: 'PaySuccess',
        component: () => import('@/views/property/paySuccess/index.vue'),
        meta: {
          title: '缴费成功'
        }
      },
      // 物业费缴纳 
      {
        path: '/propertyFee',
        name: 'PropertyFee',
        component: () => import('@/views/property/propertyFee/index.vue'),
        meta: {
          title: '物业费缴纳',
          rightTopBtn:{
            rightTopBtnPath:'/paymentRecords',
            rightTopBtnName:'缴费记录'
          }
        }
      },
      // 物业费缴纳详情
      {
        path: '/propertyFeeDetail',
        name: 'PropertyFeeDetail',
        component: () => import('@/views/property/propertyFeeDetail/index.vue'),
        meta: {
          title: '物业费缴纳详情'
        }
      },
      // 物业费记录
      {
        path: '/paymentRecords',
        name: 'PaymentRecords',
        component: () => import('@/views/property/paymentRecords/index.vue'),
        meta: {
          title: '缴费记录'
        }
      },
      // 物业费记录详情
      {
        path: '/paymentRecordsDetail',
        name: 'PaymentRecordsDetail',
        component: () => import('@/views/property/paymentRecordsDetail/index.vue'),
        meta: {
          title: '物业费记录详情'
        }
      },
      // 我的车辆
      {
        path: '/myCar',
        name: 'myCar',
        component: () => import('@/views/House/car-list.vue'),
        meta: {
          title: '我的车辆',
          rightTopBtn:{
            rightTopBtnPath:'/addCar',
            rightTopBtnName:'添加车辆'
          }
        }
      },
      // 添加车辆
      {
        path: '/addCar',
        name: 'addCar',
        component: () => import('@/views/House/add-car.vue'),
        meta: {
          title: '车辆信息'
        }
      },
      // 社区信息
      {
        path: '/messageList',
        name: 'messageList',
        component: () => import('@/views/Message/message-list.vue'),
        meta: {
          title: '社区信息'
        }
      },
      
    ]
  },
  // 退役军人服务
  // {
  //   path: '/veterans',
  //   name: 'Veterans',
  //   component: () => import('@/views/Veterans')
  // },
  // 社区通知 - 展示
  // {
  //   path: '/message',
  //   name: 'Message',
  //   component: () => import('@/views/Message')
  // },
  // // 居民电子投票 
  // {
  //   path: '/voting',
  //   name: 'Voting',
  //   component: () => import('@/views/Voting')
  // },
  // // 老人电子手环绑定 
  // {
  //   path: '/binding',
  //   name: 'Binding',
  //   component: () => import('@/views/Binding')
  // },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test')
  },
  {
    path: '*',
    redirect: '/404'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


import store from '@/store';
import {
  getToken
} from '@/utils/token.js'



router.beforeEach((to, from, next) => {
  // let openid = GetQueryString('openid') 

  // 调试openid写死 
  // let openid = 'ou_kf5thr6tZTn1T7wiK98gUkaio'
  // let openid = 'ou_kf5h8q-ge2Glo-m5WltgKVFSk'
  // let openid = 'ogjMI1GiWgF5vHs-bKkvXWLDyb15'

  // store.commit('SET_OPEN_ID', openid)

  // 不鉴权页面
  let noAuthPage = [
    '/test',
    // '/home',
    // '/visitorApplication',
    // '/community',
  ]
  if(!store.getters.openId) {
    store.dispatch('GetAppid').then(data => {
      let code = GetQueryString('code');
      let urlNow = encodeURIComponent(window.location.href);
      let appId = data.appId
      
      store.commit('SET_TENANT_ID', data.tenantId)
      if(!code) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${urlNow}&response_type=code&scope=snsapi_userinfo&connect_redirect=1#wechat_redirect`
      } else {
        store.dispatch('GetWxInfo', code)
      }
    })
  }


  // if ((!openid && !store.getters.openId) && !getToken()) {
  //   next('/register')  
  // } 
  // // 判断是否注册过
  // if (openid) {
  //   if (store.getters.openId && store.getters.openId != openid) {
  //     store.commit('SET_OPEN_ID', openid)
  //     store.dispatch('GetToken')
  //   } else {
  //     store.commit('SET_OPEN_ID', openid)
  //   }
  // }
  // 有openid 没有token 并且非注册页时 获取token
  if (store.getters.openId && !getToken() && to.path !== '/register' && noAuthPage.indexOf(to.path) === -1) {
    store.dispatch('GetToken', to.path == '/home'?true:false)
  }
  // 刷新userinfo页面
  let updateUserinfo = [
    '/home',
    '/my',
    '/community',
  ]
  // 含有 openId token  获取 userInfo 
  if (store.getters.openId && getToken() && (!store.getters.userInfo || updateUserinfo.indexOf(to.path) !== -1) && to.path !== '/register' && noAuthPage.indexOf(to.path) === -1) {
    store.dispatch("GetInfo").catch(() => {
      next('/register')
    })
  }

  next()
});

router.afterEach(() => {});

function GetQueryString (name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  var r = location.search.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  } else {
    return null
  }
}


export default router